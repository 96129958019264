<template>
  <ui-tabs
    class="text-sm"
    secondary
    :tabs="categories"
    :firstActiveTabRule="firstActiveTabRule"
    :activateTabCallback="activateTabCallback"
  />
</template>

<script>
import UiTabs from '@/components/ui/UiTabs.vue';
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import questionnairesApi from '@/services/api/questionnaires-api';

export default {
  emits: ['change-filter'],
  props: ['assessments'],
  components: {
    UiTabs,
  },

  setup(props, ctx) {
    const route = useRoute();
    const categories = ref([
      {
        id: null,
        title: 'All categories',
      },
    ]);

    const categoriesDisabled = [
      {
        id: null,
        title: 'Sustainability',
        disabled: true,
        comingSoon: true,
      },
      {
        id: null,
        title: 'Reputation',
        disabled: true,
        comingSoon: true,
      },
      {
        id: null,
        title: 'Ethics',
        disabled: true,
        comingSoon: true,
      },
    ];

    onMounted(() => {
      questionnairesApi.fetchQuestionnaireCategories().then((data) => {
        let _data = data.map((item) => {
          item.title = item.name;
          delete item.name;
          return item;
        });
        categories.value = [
          ...categories.value,
          ..._data,
          ...categoriesDisabled,
        ];
      });
    });

    const firstActiveTabRule = function (tabs) {
      return tabs.find((tab) => route.query.category == tab.id) || tabs[0];
    };

    const activateTabCallback = function (from, to) {
      ctx.emit('change-filter', {
        filter: 'category',
        value: to.id,
      });
    };

    return {
      categories,
      firstActiveTabRule,
      activateTabCallback,
    };
  },
};
</script>
